import React from 'react';

import {
  Container,
  FaqList,
  H1,
  Header,
  Seo,
  Svg,
  TextWrapper,
} from 'components';
import { useQuery } from 'styles/breakpoints';

const Faq: React.FC = React.memo(() => {
  const { isMobile } = useQuery();

  return (
    <>
      <Seo
        title="Frequently asked questions | Pulsio"
        description="Ask all the bruning questions and get them answered. If it's not here - don't hesitate to write us a message."
      />
      <Container>
        <Header />
        <Svg
          src="icons/leaves_2"
          width="52"
          height="36"
          viewBox="0 0 52 36"
          fill="none"
          style={{ display: 'block', margin: '0 auto' }}
        />
        <H1
          margin={isMobile ? '0.625rem 0 3.125rem' : '0.625rem 0 5.625rem'}
          textAlign="center"
        >
          FAQ
        </H1>
        <TextWrapper
          maxWidth="63.125rem"
          margin={isMobile ? '0 auto 4rem' : '0 auto 7.5rem'}
        >
          <FaqList />
        </TextWrapper>
      </Container>
    </>
  );
});

Faq.displayName = 'Faq';

export default Faq;
